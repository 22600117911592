import {
	Modal,
	SpaceBetween,
	Button,
	Form,
	Alert
} from "@cloudscape-design/components";

import config from "../../config";
import { connect } from "react-redux";

const CreateAccountModal = ({ visible, setVisible, accounts, setAccounts }) => {

	const handleSubmit = (e) => {
		e.preventDefault();

		fetch(`${config.api_endpoint}/accounts/create`, {
			mode: "cors",
			method: "POST",
			headers: {
				"content-type": "application/json",
			}
		})
			.then((response) => response.json())
			.then((json) => setAccounts(json["accounts"]))
			.then(() => setVisible(false))
			.catch((error) => console.error(error));
	};

	return (
		<Modal
			onDismiss={() => setVisible(false)}
			visible={visible}
			header='Create New Account'
		>
			<form onSubmit={handleSubmit}>
				<Form
					actions={
						<SpaceBetween direction='horizontal' size='xs'>
							<Button formAction='none' variant='link'>
								Cancel
							</Button>
							<Button variant='primary'>Continue</Button>
						</SpaceBetween>
					}
				>

							<Alert
						      statusIconAriaLabel="Info"
						    >
						      Rebura Sandbox Hub can create new AWS Accounts on request using Service Catalog.

						      <br />
						      <br />

							  Pressing continue will initiate the Control Tower Account Factory to provision a new account into the `rsh-account-pool` OU within your Organisation
						      <br />
						      <br />
						      It can take some time for your account to be ready for use dependant on your Control Tower complexity. It will be made available automatically.
						    </Alert>
				</Form>
			</form>
		</Modal>
	);
};

export default connect()(CreateAccountModal);
