import { connect } from "react-redux";
import { useEffect, useState } from 'react';
import {
	SpaceBetween,
	Container,
	Header,
	CodeEditor,
	Button,
	StatusIndicator
 } from "@cloudscape-design/components";

 import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/cloud_editor.css';
import 'ace-builds/css/theme/cloud_editor_dark.css';

import RSHAppLayout from "../layout/RSHAppLayout";

import config from "../../config";

const i18nStrings = {
  	'loadingState': "Loading account cleanup config"
  }

const Settings = ({token}) => {

  const [value, setValue] = useState('');
  const [preferences, setPreferences] = useState({});
  const [loading, setLoading] = useState(true);
  const [ace, setAce] = useState();
  const [buttonVariant, setButtonVariant] = useState('normal');
  const [saveStatusIndicator, setSaveStatusIndicator] = useState('loading');
  const [saveStatusMessage, setSaveStatusMessage] = useState('loading');

  useEffect(() => {
		fetch(`${config.api_endpoint}/settings/nuke-config`, {
			mode: "cors",
			method: "GET",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
		})
		.then((response) => response.json())
		.then((json) => setValue(json.setting.content))
		.then(() => setSaveStatusIndicator('success'))
		.then(() => setSaveStatusMessage('Loaded'))
		.then(() => setLoading(false))
		.catch((error) => console.error(error));
	}, [token]);

  useEffect(() => {
    async function loadAce() {
      const ace = await import('ace-builds');
      // await import('ace-builds/webpack-resolver');
      ace.config.set('useStrictCSP', true);

      return ace;
    }
    loadAce()
      .then(ace => setAce(ace))
  }, []);

  const saveCode = () => {

  	setSaveStatusIndicator('loading')
	setSaveStatusMessage('Saving...')

  	fetch(`${config.api_endpoint}/settings/nuke-config`, {
		mode: "cors",
		method: "PUT",
		headers: {
			"content-type": "application/json",
			"x-authorization": `Bearer ${token}`,
		},
		body: JSON.stringify({
			content: value,
		}),
	})
	.then((response) => response.json())
	.then(() => setLoading(false))
	.then(() => setSaveStatusIndicator('success'))
	.then(() => setSaveStatusMessage('Saved'))
	.then(() => setButtonVariant('normal'))
	.catch((error) => console.error(error));

  }
  const codeChange = (event) => {
  	setButtonVariant('primary')
  	setValue(event.detail.value)
  	setSaveStatusIndicator('warning')
  	setSaveStatusMessage('Unsaved Changes')
  }

	return (
		<RSHAppLayout page_title='Settings'>
			<SpaceBetween size='l'>

				<Container header={<Header
					variant='h3'
					actions={
						<SpaceBetween direction='horizontal' size='xs'>
							<Button variant={buttonVariant} onClick={saveCode}>
								Save
							</Button>
						</SpaceBetween>

					}
				>
					Account Cleanup Config
				</Header>}
				>
					<StatusIndicator type={saveStatusIndicator}>
							{saveStatusMessage}
					</StatusIndicator>
					<CodeEditor
					  i18nStrings={i18nStrings}
				      ace={ace}
				      value={value}
				      language="yaml"
				      onDelayedChange={codeChange}
				      preferences={preferences}
				      onPreferencesChange={event => setPreferences(event.detail)}
				      loading={loading}
				      // should match the imports on top of this file
				      themes={{ light: ['cloud_editor'], dark: ['cloud_editor_dark'] }}
				    />
				</Container>


			</SpaceBetween>
		</RSHAppLayout>
	);
};


const mapStateToProps = (state) => ({
	token: state.token.accessToken,
});

export default connect(mapStateToProps)(Settings);
