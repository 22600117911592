import { connect } from "react-redux";
import { SpaceBetween } from "@cloudscape-design/components";

import RSHAppLayout from "../layout/RSHAppLayout";
import LeaseOverview from "../leases/LeaseOverview";

const Leases = () => {
	return (
		<RSHAppLayout page_title=''>
			<SpaceBetween size='l'>
				<LeaseOverview />
			</SpaceBetween>
		</RSHAppLayout>
	);
};

export default connect()(Leases);
