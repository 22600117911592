import * as React from 'react';
import {
  StatusIndicator
 } from '@cloudscape-design/components';

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

function createLabelFunction(columnName) {
  return ({ sorted, descending }) => {
    const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
    return `${columnName}, ${sortState}.`;
  };
}

const getStatusIndicator = (status) => {
  switch (status) {
    case "Available":
      return "success";
    case "Leased":
      return "in-progress";
    case "Deleting":
    case "Cleaning In Progress":
    case "Provisioning":
      return "loading";
    case "Dirty":
      return "warning";
    case "Cleanup Error":
    case "Provisioning Error":
      return "error";
    default:
      return "info";
  }
};

const formatTimestamp = (ts) => {
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(ts * 1000);
  };

export const columnDefinitions = [
  {
    id: 'aws_account_id',
    header: 'Account ID',
    cell: item => item.AccountID,
    ariaLabel: createLabelFunction('aws_account_id'),
    sortingField: 'aws_account_id',
    isRowHeader: true,
  },
  {
    id: 'name',
    header: 'Name',
    cell: item => item.Description,
    ariaLabel: createLabelFunction('Name'),
    sortingField: 'name',
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => <StatusIndicator type={getStatusIndicator(item.Status)}>{item.Status}</StatusIndicator>,
    ariaLabel: createLabelFunction('Status'),
    sortingField: 'status',
  },

  {
    id: 'leased_to',
    header: 'Leased To',
    cell: item => item.LeasedTo,
    ariaLabel: createLabelFunction('Leased To'),
    sortingField: 'leased_to',
  },
  {
    id: 'lease_id',
    header: 'Lease ID',
    cell: item => item.LeaseID,
    ariaLabel: createLabelFunction('Lease ID'),
    sortingField: 'lease_id',
  },
  {
    id: 'lease_expiry_date',
    header: 'Lease Expiry Date',
    cell: item => item.LeaseExpiryDate ? formatTimestamp(item.LeaseExpiryDate) : "",
    ariaLabel: createLabelFunction('Lease Expiry Date'),
    sortingField: 'lease_expiry_date',
  },
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 accounts' },
    { value: 20, label: '20 accounts' },
    { value: 50, label: '50 accounts' },
  ],
};

const contentDisplayPreference = {
  title: 'Column preferences',
  description: 'Customize the columns visibility and order.',
  options: columnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'customer_code' })),
};
export const collectionPreferencesProps = {
  pageSizePreference,
  contentDisplayPreference,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};