import { connect } from "react-redux";
import { SpaceBetween } from "@cloudscape-design/components";

import RSHAppLayout from "../layout/RSHAppLayout";
import AccountsOverview from "../accounts/AccountsOverview";

const Accounts = () => {
	return (
		<RSHAppLayout page_title=''>
			<SpaceBetween size='l'>
				<AccountsOverview />
			</SpaceBetween>
		</RSHAppLayout>
	);
};

export default connect()(Accounts);
