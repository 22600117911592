import { connect } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import config from "../../config";

import {
  Box,
  ColumnLayout,
  Container,
  StatusIndicator,
  ButtonDropdown,
  CopyToClipboard,
  Flashbar,
} from "@cloudscape-design/components";

const Lease = ({ lease, leases, setLeases, key_id, token }) => {
  const [flashMessages, setFlashMessages] = useState([]);

  const navigate = useNavigate()
  const viewLogs = async (key_id) => {
    navigate(`/logs/${key_id}`);
  };

  const deleteLease = async (key) => {
    let items = [...leases];

    fetch(`${config.api_endpoint}/leases/${items[key]["LeaseID"]}`, {
      mode: "cors",
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        "x-authorization": `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        items[key] = json["lease"];

        items[key]["Status"] = "Deleting";
        setLeases(items);
        setFlashMessages([
          {
            type: "success",
            loading: true,
            content:
              "Your lease has ended and account cleanup is now starting. If the account cannot be cleaned, you will be required to correct the error before obtaining a new lease.",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setFlashMessages([]),
            id: "lease_deleting",
          },
        ]);
      });
  };

  const archiveLease = async (key) => {
    let items = [...leases];

    fetch(`${config.api_endpoint}/leases/${items[key]["LeaseID"]}/archive`, {
      mode: "cors",
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "x-authorization": `Bearer ${token}`,
      },
    }).then((response) => {
      items.splice(key, 1);
      setLeases(items);
      setFlashMessages([
        {
          type: "success",
          loading: true,
          content: "Your lease has been archived.",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () => setFlashMessages([]),
          id: "lease_deleting",
        },
      ]);
    });
  };

  const getStatusIndicator = (status) => {
    switch (status) {
      case "Active":
        return "success";
      case "Deleting":
      case "Account Cleanup In Progress":
        return "in-progress";
      case "Account Cleanup Failed":
        return "error";
      default:
        return "error";
    }
  };

  const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );

  const formatTimestamp = (ts) => {
    console.log(ts);
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(ts * 1000);
  };

  return (
    <Container key={key_id}>
      <Flashbar items={flashMessages} />

      <b>Lease ID:</b>
      <CopyToClipboard
        copyButtonText="Copy"
        copyErrorText="ID failed to copy"
        copySuccessText="ID copied"
        textToCopy={lease["LeaseID"]}
        variant="inline"
      />

      <hr />
      <br />

      <ColumnLayout columns={6} variant="text-grid" minColumnWidth="100">
        <ValueWithLabel label="Lease Status">
          <StatusIndicator type={getStatusIndicator(lease["Status"])}>
            {lease["Status"]}
          </StatusIndicator>
        </ValueWithLabel>

        <ValueWithLabel label="Account">{lease["AccountName"]} ({lease["AccountID"]})</ValueWithLabel>

        <ValueWithLabel label="Start Date">
          {formatTimestamp(lease["StartDate"])}
        </ValueWithLabel>

        <ValueWithLabel label="Expiry Date">
          {formatTimestamp(lease["ExpiryDate"])}
        </ValueWithLabel>

        <ValueWithLabel label="End Date">
          {lease["EndDate"] ? formatTimestamp(lease["EndDate"]) : "N/A"}
        </ValueWithLabel>

        <ButtonDropdown
          onItemClick={(e) => {
            switch (e.detail.id) {
              case "delete":
                deleteLease(key_id);
                break;
              case "archive":
                archiveLease(key_id);
                break;
              case "viewlogs":
                viewLogs(lease["LeaseID"]);
                break;
              default:
                return e.preventDefault();
            }
          }}
          items={[
            {
              text: "Delete",
              id: "delete",
              disabled: lease.Status !== "Active",
            },
            {
              text: "View Nuke Logs",
              id: "viewlogs",
              disabled: lease.Status !== "Account Cleanup Failed",
            },
            {
              text: "Extend",
              id: "extend",
              disabled: true //lease.Status !== "Active",
            },
            {
              text: "Archive",
              id: "archive",
              disabled: lease.Status !== "Deleted",
            },
          ]}
          disabled={lease["Status"] === "Deleting"}
          loading={lease["Status"] === "Deleting"}
        >
          {lease["Status"] === "Deleting" ? "Deleting" : "Actions"}
        </ButtonDropdown>
      </ColumnLayout>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(Lease);
