import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  SpaceBetween,
  Container,
  Header,
  StatusIndicator,
} from "@cloudscape-design/components";

import { useParams } from "react-router-dom";

import * as React from "react";

import { CodeView } from "@cloudscape-design/code-view";

import CopyToClipboard from "@cloudscape-design/components/copy-to-clipboard";

import "ace-builds/css/ace.css";
import "ace-builds/css/theme/cloud_editor.css";
import "ace-builds/css/theme/cloud_editor_dark.css";

import RSHAppLayout from "../layout/RSHAppLayout";

import config from "../../config";


const Settings = ({ token }) => {
  const { id } = useParams();

  const [value, setValue] = useState("");
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [ace, setAce] = useState();
  const [saveStatusIndicator, setSaveStatusIndicator] = useState("loading");
  const [saveStatusMessage, setSaveStatusMessage] = useState("loading");

  useEffect(() => {
    fetch(`${config.api_endpoint}/viewlogs/${id}`, {
      mode: "cors",
      method: "GET",
      headers: {
        "content-type": "application/json",
        "x-authorization": `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => setValue(json.logs))
      .then(() => setSaveStatusIndicator("success"))
      .then(() => setSaveStatusMessage("Loaded"))
      .then(() => setLoading(false))
      .catch((error) => console.error(error));
  // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    async function loadAce() {
      const ace = await import("ace-builds");
      // await import('ace-builds/webpack-resolver');
      ace.config.set("useStrictCSP", true);

      return ace;
    }
    loadAce().then((ace) => setAce(ace));
  }, []);

  return (
    <RSHAppLayout page_title="View Logs">
      <SpaceBetween size="l">
        <Container header={<Header variant="h3">View Logs</Header>}>
          <StatusIndicator type={saveStatusIndicator}>
            {saveStatusMessage}
          </StatusIndicator>
          <CodeView
            content={value}
            lineNumbers
            actions={
              <CopyToClipboard
                copyButtonAriaLabel="Copy code"
                copyErrorText="Code failed to copy"
                copySuccessText="Code copied"
                textToCopy={value}
              />
            }
          />
        </Container>
      </SpaceBetween>
    </RSHAppLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(Settings);
