import { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import {
  Container,
  Flashbar
} from '@cloudscape-design/components';

import RSHAppLayout from '../layout/RSHAppLayout'

const LoginError = () => {

  const { search } = useLocation();
  const { error } = queryString.parse(search);

  const [items, setItems] = useState([
    {
      type: "error",
      content: error,
      // action: <Button>View instance</Button>,
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setItems([]),
      id: "message_1"
    }
  ]);


  return (

    <RSHAppLayout page_title="Login Error" show_breadcrumbs={false} show_side_nav={false} nav_open={false}>
      <Flashbar items={items} />
      <Container>
        From Identity Provider: {error}
      </Container>
    </RSHAppLayout>

  );
};

export default LoginError
