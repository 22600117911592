import { useState } from "react";
import {
	Modal,
	SpaceBetween,
	Button,
	Form,
	FormField,
	Input,
	Container,
} from "@cloudscape-design/components";

import config from "../../config";
import { connect } from "react-redux";

const AddAccountModal = ({ visible, setVisible, accounts, setAccounts }) => {
	const [formState, setFormState] = useState({});

	const handleSubmit = (e) => {
		e.preventDefault();

		fetch(`${config.api_endpoint}/accounts`, {
			mode: "cors",
			method: "POST",
			headers: {
				"content-type": "application/json",
			},
			body: JSON.stringify({
				AccountID: String(formState["aws_account_id"]),
				Description: String(formState["description"]),
			}),
		})
			.then((response) => response.json())
			.then((json) => setAccounts(json["accounts"]))
			.then(() => setVisible(false))
			.then(() => setFormState({}))
			.catch((error) => console.error(error));
	};

	return (
		<Modal
			onDismiss={() => setVisible(false)}
			visible={visible}
			header='Add Existing Account'
		>
			<form onSubmit={handleSubmit}>
				<Form
					actions={
						<SpaceBetween direction='horizontal' size='xs'>
							<Button formAction='none' variant='link'>
								Cancel
							</Button>
							<Button variant='primary'>Submit</Button>
						</SpaceBetween>
					}
				>
					<Container>
						<SpaceBetween direction='vertical' size='l'>
							<FormField label='AWS Account ID'>
								<Input
									value={formState["aws_account_id"]}
									onChange={(e) =>
										setFormState({
											...formState,
											aws_account_id: e.detail.value,
										})
									}
								/>
							</FormField>

							<FormField label='Description'>
								<Input
									value={formState["description"]}
									onChange={(e) =>
										setFormState({
											...formState,
											description: e.detail.value,
										})
									}
								/>
							</FormField>
						</SpaceBetween>
					</Container>
				</Form>
			</form>
		</Modal>
	);
};

export default connect()(AddAccountModal);
