import { connect } from "react-redux";
import { SpaceBetween, Container } from "@cloudscape-design/components";

import RSHAppLayout from "../layout/RSHAppLayout";

const Forbidden = () => {
	return (
		<RSHAppLayout page_title='Forbidden'>
			<SpaceBetween size='l'>
				<Container>
					You dont have the correct permissions to see this stuff
				</Container>
			</SpaceBetween>
		</RSHAppLayout>
	);
};

export default connect()(Forbidden);
