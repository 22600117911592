import { useState, useEffect } from "react";
import { connect } from "react-redux";

import config from "../../config";

import {
	Button,
	Container,
	Header,
	SpaceBetween,
	Icon,
	Flashbar,
} from "@cloudscape-design/components";

import Lease from "./Lease";

const LeaseList = ({ title, show_create, filter, token, hide_empty }) => {
	const [leases, setLeases] = useState([]);

	const [flashMessages, setFlashMessages] = useState([]);

	useEffect(() => {
		fetch(`${config.api_endpoint}/leases`, {
			mode: "cors",
			method: "GET",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((json) => {
				let leases = [];

				let exclude = false;
				if (filter[0] === "!") {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					filter = filter.substring(1);
					exclude = true;
				}
				const filters = filter.split(",");

				for (let key in json["leases"]) {
					let lease = json["leases"][key];

					if (exclude) {
						if (!filters.includes(lease.Status)) {
							leases.push(lease);
						}
					} else {
						if (filters.includes(lease.Status)) {
							leases.push(lease);
						}
					}
				}
				setLeases(leases);
			})
			.catch((error) => console.error(error));
	}, []);

	const createLease = async (key) => {
		fetch(`${config.api_endpoint}/leases`, {
			mode: "cors",
			method: "POST",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					response.json().then((json) => {
						let items = [...leases];
						items.unshift(json["lease"]);
						setLeases(items);
						setFlashMessages([
							{
								header: "Successfully Created New Lease",
								type: "success",
								content: "Your new lease is active and you can access the account by visiting your AWS Access Portal",
								dismissible: true,
								dismissLabel: "Dismiss message",
								onDismiss: () => setFlashMessages([]),
								id: "new_lease",
							},
						]);
					});
				}

				if (response.status === 400) {
					response.json().then((json) => {
						setFlashMessages([
							{
								header: "Error Creating Lease",
								type: "error",
								content: json["error"],
								dismissible: true,
								dismissLabel: "Dismiss message",
								onDismiss: () => setFlashMessages([]),
								id: "create_lease_error",
							},
						]);
					});
				}
			})
			.catch((error) => console.error(error));
	};

	if (!hide_empty || leases.length > 0) {
		return (
			<Container
				header={
					<Header
						variant='h3'
						actions={
							show_create ? (
								<SpaceBetween direction='horizontal' size='xs'>
									<Button
										onClick={() => {
											createLease();
										}}
									>
										Create New Lease{" "}
										<Icon name='add-plus' />
									</Button>
								</SpaceBetween>
							) : (
								false
							)
						}
					>
						{title} ({leases.length})
					</Header>
				}
			>
				<SpaceBetween size='s'>
					<Flashbar items={flashMessages} />

					{leases.map((lease, key) => (
						<Lease
							lease={lease}
							key={key}
							key_id={key}
							leases={leases}
							setLeases={setLeases}
						/>
					))}
				</SpaceBetween>
			</Container>
		);
	} else {
		return <div />;
	}
};

const mapStateToProps = (state) => ({
	token: state.token.accessToken,
});

export default connect(mapStateToProps)(LeaseList);
