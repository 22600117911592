
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useNavigate } from "react-router-dom";

import config from "../../config";

import {
  Box,
  Button,
  Header,
  SpaceBetween,
  ButtonDropdown,
  Icon,
  CollectionPreferences,
  Pagination,
  Table,
  TextFilter,
} from "@cloudscape-design/components";

import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './AccountsListTableConfig';

import AddAccountModal from "./AddAccountModal";
import CreateAccountModal from "./CreateAccountModal";

const AccountsOverview = ({ token, title }) => {

	const [accounts, setAccounts] = useState([]);
	const [visible, setAddExistingVisible] = useState(false);
	const [createVisible, setCreateNewAccountVisible] = useState(false);

  const navigate = useNavigate()

  const EmptyState = ({ title, subtitle, action }) => {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }

  const [preferences, setPreferences] = useState({
    pageSize: 20,
    contentDisplay: [
      { id: 'aws_account_id', visible: true },
      { id: 'status', visible: true },
      { id: 'name', visible: true },
      { id: 'leased_to', visible: true },
      { id: 'lease_id', visible: true },
      { id: 'lease_expiry_date', visible: true },
    ],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    accounts,
    {
      filtering: {
        empty: <EmptyState title="No accounts" />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  const { selectedItems } = collectionProps;

  const handleDropdownClick = (e) => {

    e.preventDefault()

    switch (e.detail.id) {
      case 'delete':

        fetch(`${config.api_endpoint}/accounts/${selectedItems[0]['AccountID']}`, {
          mode: "cors",
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            "x-authorization": `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((json) => setAccounts(json["accounts"]))
          .catch((error) => console.error(error));
        break

      case 'endandnuke':

        fetch(`${config.api_endpoint}/leases/${selectedItems[0]['LeaseID']}/nuke`, {
          mode: "cors",
          method: "POST",
          headers: {
            "content-type": "application/json",
            "x-authorization": `Bearer ${token}`,
          },
          body: JSON.stringify({
            email: selectedItems[0]['LeasedTo']
          })
        })
          .then((response) => response.json())
          .then((json) => setAccounts(json["accounts"]))
          .catch((error) => console.error(error));
        break

      case 'nukelog':
        let path = `/logs/${selectedItems[0]['LeaseID']}`;
        navigate(path);
        break

      default:
        break

      }

  }

  useEffect(() => {
    fetch(`${config.api_endpoint}/accounts`, {
      mode: "cors",
      method: "GET",
      headers: {
        "content-type": "application/json",
        "x-authorization": `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => setAccounts(json["accounts"]))
      .catch((error) => console.error(error));
  }, [token]);


	return (
    <>

     <AddAccountModal
                visible={visible}
                setVisible={setAddExistingVisible}
                accounts={accounts}
                setAccounts={setAccounts}
            />

            <CreateAccountModal
                visible={createVisible}
                setVisible={setCreateNewAccountVisible}
                accounts={accounts}
                setAccounts={setAccounts}
            />

    <Table
          {...collectionProps}
          selectionType="multi"
          header={
          <Header
            counter={
              selectedItems.length
                ? "(" + selectedItems.length + "/" + accounts.length + ")"
                : "(" + accounts.length + ")"
            }
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                <ButtonDropdown
                  onItemClick={handleDropdownClick}
                  items={[
                    {
                      text: "End Lease & Nuke",
                      id: "endandnuke",
                      disabled: selectedItems.length === 1 && (selectedItems[0]['Status'] === "Dirty" || selectedItems[0]['Status'] === "Leased") ? false : true
                    },
                    {
                      text: "View Nuke Logs",
                      id: "nukelog",
                      disabled: selectedItems.length === 1 && selectedItems[0]['LeaseID'] && selectedItems[0]['Status'] !== "Leased" ? false : true
                    },
                    {
                      text: "Delete Account",
                      id: "delete",
                      disabled: selectedItems.length === 1 ? false : true
                    }
                  ]}
                >
                  Actions
                </ButtonDropdown>

                    <Button
                        onClick={() => {
                            setAddExistingVisible(true);
                        }}
                    >
                        Add Existing Account <Icon name='add-plus' />
                    </Button>

                    <Button
                        onClick={() => {
                            setCreateNewAccountVisible(true);
                        }}
                    >
                        Create New Account <Icon name='add-plus' />
                    </Button>
                </SpaceBetween>
            }
          >
            Accounts
          </Header>
          }
          columnDefinitions={columnDefinitions}
          columnDisplay={preferences.contentDisplay}
          items={items}
          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
          filter={
            <TextFilter
              {...filterProps}
              countText={getMatchesCountText(filteredItemsCount)}
              filteringAriaLabel="Filter accounts"
            />
          }
          preferences={
            <CollectionPreferences
              {...collectionPreferencesProps}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
            />
          }
        >

        </Table>
      </>

  );
};

const mapStateToProps = (state) => ({
  token: state.token.accessToken,
});

export default connect(mapStateToProps)(AccountsOverview);
